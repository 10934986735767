import { useSelector } from 'react-redux'
import * as JivoSite from 'react-jivosite'

export default function JivoChat() {
  const { settings } = useSelector((state) => state.settings);
  const jivoChat = settings?.jivoChat || ''
  return (
    <>
      {jivoChat && (
        <JivoSite.Widget id={jivoChat} />
      )}
    </>
  )
}