import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
   isAuthenticated: false,
   isInitialized: false,
   user: null,
};

const handlers = {
   AFILIATE_CODE: (state, action) => {
      const { newAffiliateCode } = action.payload;
      return {
         ...state,
         user: {
            affiliateCode: newAffiliateCode,
         },
      };
   },

   INITIALIZE: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      return {
         ...state,
         isAuthenticated,
         isInitialized: true,
         user,
      };
   },
   LOGIN: (state, action) => {
      const { user } = action.payload;

      return {
         ...state,
         isAuthenticated: true,
         user,
      };
   },
   LOGOUT: (state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
   }),
   REGISTER: (state, action) => {
      const { user } = action.payload;

      return {
         ...state,
         isAuthenticated: true,
         user,
      };
   },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
   ...initialState,
   method: 'jwt',
   login: () => Promise.resolve(),
   loginClient: () => Promise.resolve(),
   logout: () => Promise.resolve(),
   register: () => Promise.resolve(),
   setUserAffiliateCode: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
   children: PropTypes.node,
};

function AuthProvider({ children }) {
   const [state, dispatch] = useReducer(reducer, initialState);

   useEffect(() => {
      const initialize = async () => {
         try {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken && isValidToken(accessToken)) {
               setSession(accessToken);

               const userId = localStorage.getItem('userId');

               const response = await axios.get(`/api/user/${userId}`);
               const { user } = response.data;

               //               console.log('USUARIO', user);

               dispatch({
                  type: 'INITIALIZE',
                  payload: {
                     isAuthenticated: true,
                     user,
                  },
               });
            } else {
               dispatch({
                  type: 'INITIALIZE',
                  payload: {
                     isAuthenticated: false,
                     user: null,
                  },
               });
            }
         } catch (err) {
            console.error(err);
            dispatch({
               type: 'INITIALIZE',
               payload: {
                  isAuthenticated: false,
                  user: null,
               },
            });
         }
      };

      initialize();
   }, []);

   const setUserAffiliateCode = (newAffiliateCode) => {
      dispatch({
         type: 'AFILIATE_CODE',
         payload: { newAffiliateCode },
      });
   };

   const login = async (email, password) => {
      const response = await axios.post('/api/auth/login', {
         email,
         password,
      });
      //      console.log(response);
      const { accessToken, user } = response.data;

      setSession(accessToken);
      localStorage.setItem('userId', user.id);

      dispatch({
         type: 'LOGIN',
         payload: {
            user,
         },
      });
   };

   const loginClient = async (login, checkout = false) => {
      const response = await axios.post('/api/auth/login/client', {
         login,
         checkout
      });

      const { accessToken, user } = response.data;

      setSession(accessToken);
      localStorage.setItem('userId', user.id);

      dispatch({
         type: 'LOGIN',
         payload: {
            user,
         },
      });
   };

   const register = async (email, password, firstName, lastName) => {
      const response = await axios.post('/api/account/register', {
         email,
         password,
         firstName,
         lastName,
      });
      const { accessToken, user } = response.data;

      localStorage.setItem('accessToken', accessToken);

      dispatch({
         type: 'REGISTER',
         payload: {
            user,
         },
      });
   };

   const logout = async () => {
      setSession(null);
      dispatch({ type: 'LOGOUT', payload: { user: null } });
   };

   return (
      <AuthContext.Provider
         value={{
            ...state,
            method: 'jwt',
            login,
            loginClient,
            logout,
            register,
            setUserAffiliateCode,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
}

export { AuthContext, AuthProvider };
