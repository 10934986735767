export const dateMask = (value) =>
   value
      .replace(/\D/g, '') // Remove qualquer caracter que não seja número
      .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona uma barra após o dia
      .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona uma barra após o mês
      .replace(/(\d{4})(\d)/, '$1'); // Limita a 8 dígitos para o ano

export const cpfMask = (value) =>
   value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

export const phoneNumberMask = (inputValue) => {
   // Remove non-digits from input
   const numericValue = inputValue.replace(/\D/g, '');

   // Apply phone number mask
   if (numericValue.length <= 2) {
      return numericValue;
   }
   if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`;
   }
   if (numericValue.length <= 10) {
      return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 6)}-${numericValue.slice(6)}`;
   }
   return `(${numericValue.slice(0, 2)}) ${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;
};
