import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   isLoading: false,
   error: null,
   winners: [],
   winner: null,
};

const slice = createSlice({
   name: 'winners',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getWinnersSuccess(state, action) {
         state.isLoading = false;
         state.winners = action.payload;
      },

      setWinners(state, action) {
         state.isLoading = false;
         state.winners = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getWinners() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/winners');
         dispatch(slice.actions.getWinnersSuccess(response.data));
         //         console.log('AQUIII WINNERS', response.data);
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

// ----------------------------------------------------------------------

export function setWinners(winners) {
   return async () => {
      dispatch(slice.actions.setWinners(winners));
   };
}
