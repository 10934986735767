import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   error: null,
   hasLoaded: false,
   socialMedias: [],
};

const slice = createSlice({
   name: 'socialMedias',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getSocialMediasSuccess(state, action) {
         state.hasLoaded = true;
         state.isLoading = false;
         state.socialMedias = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getSocialMedias() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/settings/social-medias');
         dispatch(slice.actions.getSocialMediasSuccess(response.data.socialMedias));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
