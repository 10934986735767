import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   isLoading: false,
   error: null,
   users: [],
   user: null,
};

const slice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getUsersSuccess(state, action) {
         state.isLoading = false;
         state.users = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get('/api/users');
         dispatch(slice.actions.getUsersSuccess(response.data.users));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}

export function getUserById(id) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/user/${id}`);
         dispatch(slice.actions.getUsersSuccess(response.data.user));
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
