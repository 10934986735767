import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
   baseURL: HOST_API,
});

// Função para obter o valor de um cookie
// eslint-disable-next-line consistent-return
function getCookie(name) {
   const value = `; ${document.cookie}`;
   const parts = value.split(`; ${name}=`);
   if (parts.length === 2)
      return parts.pop().split(';').shift();

   return ''
}

// Interceptor para adicionar os cookies como parâmetros em todas as requisições
axiosInstance.interceptors.request.use(
   (config) => {
      // Obtenha os cookies _fbp e _fbc, se existirem
      const fbp = getCookie('_fbp');
      const fbc = getCookie('_fbc');


      // Se os cookies existirem, adicione-os aos cabeçalhos da requisição
      if (fbp || fbc) {
         config.headers = {
            ...config.headers,
            'X-Fbp': fbp || '',
            'X-Fbc': fbc || '',
         };
      }


      return config;
   },
   (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
   (response) => response,
   (error) => Promise.reject((error.response && error.response.data) || 'Algo deu errado com a solicitação!')
);

export default axiosInstance;
