import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const { pathname } = useLocation();

   return (
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
         <Component {...props} />
      </Suspense>
   );
};

export default function Router() {
   return useRoutes([
      {
         // path: 'auth',
         // children: [
         //    {
         //       path: 'login',
         //       element: (
         //          <GuestGuard>
         //             <Login />
         //          </GuestGuard>
         //       ),
         //    },
         //    {
         //       path: 'register',
         //       element: (
         //          <GuestGuard>
         //             <Register />
         //          </GuestGuard>
         //       ),
         //    },
         //    { path: 'login-unprotected', element: <Login /> },
         //    { path: 'register-unprotected', element: <Register /> },
         //    { path: 'reset-password', element: <ResetPassword /> },
         //    { path: 'new-password', element: <NewPassword /> },
         //    { path: 'verify', element: <VerifyCode /> },
         // ],
      },

      // Dashboard Routes
      // desabilitado o dash para esse projeto
      /*
      {
         path: 'dashboard',
         element: (
            <AuthGuard>
               <DashboardLayout />
            </AuthGuard>
         ),
         children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'app', element: <GeneralApp /> },
            { path: 'ecommerce', element: <GeneralEcommerce /> },
            { path: 'analytics', element: <GeneralAnalytics /> },
            { path: 'banking', element: <GeneralBanking /> },
            { path: 'booking', element: <GeneralBooking /> },
            // produtos
            { path: 'products', element: <ProductList /> },
            { path: 'products/new', element: <ProductCreate /> },
            { path: 'products/:name/edit', element: <ProductEdit /> },
            // communications
            { path: 'communications', element: <CommunicationsList /> },
            { path: 'communications/:id/edit', element: <CommunicationsEdit /> },
            { path: 'communications/new', element: <CommunicationsCreate /> },
            // settings
            { path: 'settings/faqs', element: <FAQList /> },
            { path: 'settings/faqs/new', element: <FAQCreate /> },
            { path: 'settings/faqs/:id/edit', element: <FAQEdit /> },
            { path: 'settings/social-medias', element: <SettingsSocialMedias /> },
            { path: 'settings/terms', element: <SettingsTerms /> },
            { path: 'settings/params', element: <SettingsParams /> },
            { path: 'settings/themes', element: <SettingsThemes /> },
            { path: 'settings/certificate', element: <SettingsCertificate /> },

            {
               path: 'e-commerce',
               children: [
                  { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
                  { path: 'shop', element: <EcommerceShop /> },
                  { path: 'list', element: <EcommerceProductList /> },
                  { path: 'product/new', element: <EcommerceProductCreate /> },
                  { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
                  { path: 'checkout', element: <EcommerceCheckout /> },
               ],
            },
            {
               path: 'user',
               children: [
                  { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
                  { path: 'profile', element: <UserProfile /> },
                  { path: 'cards', element: <UserCards /> },
                  { path: 'list', element: <UserList /> },
                  { path: 'new', element: <UserCreate /> },
                  { path: ':name/edit', element: <UserCreate /> },
                  { path: 'account', element: <UserAccount /> },
               ],
            },
            {
               path: 'invoice',
               children: [
                  { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
                  { path: ':id/orders', element: <InvoiceList /> },

                  { path: 'list', element: <InvoiceList /> },
                  { path: ':id', element: <InvoiceDetails /> },
                  { path: ':id/edit', element: <InvoiceEdit /> },
                  { path: 'new', element: <InvoiceCreate /> },
               ],
            },
            {
               path: 'blog',
               children: [
                  { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
                  { path: 'posts', element: <BlogPosts /> },
                  { path: 'post/:title', element: <BlogPost /> },
                  { path: 'new', element: <BlogNewPost /> },
               ],
            },
            {
               path: 'mail',
               children: [
                  { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
                  { path: 'label/:customLabel', element: <Mail /> },
                  { path: 'label/:customLabel/:mailId', element: <Mail /> },
                  { path: ':systemLabel', element: <Mail /> },
                  { path: ':systemLabel/:mailId', element: <Mail /> },
               ],
            },
            {
               path: 'chat',
               children: [
                  { element: <Chat />, index: true },
                  { path: 'new', element: <Chat /> },
                  { path: ':conversationKey', element: <Chat /> },
               ],
            },
            { path: 'calendar', element: <Calendar /> },
            { path: 'kanban', element: <Kanban /> },
            { path: 'permission-denied', element: <PermissionDenied /> },
         ],
      },
      */

      // Main Routes
      {
         path: '*',
         // element: <LogoOnlyLayout />,
         element: <MainLayout />,
         children: [
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: 'maintenance', element: <Maintenance /> },
            { path: 'compra/:id', element: <Pricing /> },
            { path: 'campanhas', element: <Campaigns /> },
            { path: 'ganhadores', element: <Winners /> },
            { path: 'meus-numeros', element: <MyNumbers /> },
            { path: 'campanha/:name', element: <EcommerceProductDetails /> },
            { path: 'termos-de-uso', element: <Terms /> },
            { path: 'comunicados', element: <Communications /> },
            { path: 'payment', element: <Payment /> },
            { path: 'afiliados', element: <Affiliates /> },

            { path: '500', element: <Page500 /> },
            { path: '404', element: <Page404 /> },
            { path: '403', element: <Page403 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
         ],
      },
      {
         path: '/',
         element: <MainLayout />,
         children: [
            { element: <HomePage />, index: true },
            { path: 'about-us', element: <About /> },
            { path: 'contato', element: <Contact /> },
            { path: 'faqs', element: <Faqs /> },
         ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
   ]);
}
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/EcommerceProductDetails')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

const Pricing = Loadable(lazy(() => import('../pages/Pricing')));

const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

const Terms = Loadable(lazy(() => import('../pages/Terms')));
const Communications = Loadable(lazy(() => import('../pages/Communications')));
const Campaigns = Loadable(lazy(() => import('../pages/Campaigns')));
const Winners = Loadable(lazy(() => import('../sections/home/Winners')));
const MyNumbers = Loadable(lazy(() => import('../sections/home/MyNumbers')));
const Affiliates = Loadable(lazy(() => import('../sections/home/Affiliates')));
