import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

const initialState = {
   error: null,
   hasLoaded: false,
   product: null,
   orders: [],
   order: null,
};

const slice = createSlice({
   name: 'order',
   initialState,
   reducers: {
      // START LOADING
      startLoading(state) {
         state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
         state.isLoading = false;
         state.error = action.payload;
      },

      getOrdersSuccess(state, action) {
         state.isLoading = false;
         state.orders = action.payload;
      },

      getProductSuccess(state, action) {
         state.isLoading = false;
         state.product = action.payload;
      },
   },
});

// Reducers
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(id) {
   return async () => {
      dispatch(slice.actions.startLoading());
      try {
         const response = await axios.get(`/api/product/${id}/orders`);
         dispatch(slice.actions.getOrdersSuccess(response.data.orders));
         dispatch(slice.actions.getProductSuccess(response.data.product));

         //         console.log('AQUIOH', response.data);
      } catch (error) {
         dispatch(slice.actions.hasError(error));
      }
   };
}
