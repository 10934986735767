// @mui
import { Container, Grid, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonProduct() {
   return (
      <Container sx={{ pt: 3 }}>
         <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
               <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '50%', borderRadius: 2 }} />

               {/* <Skeleton variant="circular" width={80} height={80} /> */}
               <Skeleton variant="text" height={240} />
               <Skeleton variant="text" height={40} />
               <Skeleton variant="text" height={40} />
               <Skeleton variant="text" height={40} />
               <Skeleton variant="text" height={240} />
               <Skeleton variant="text" height={40} />
               <Skeleton variant="text" height={40} />
               <Skeleton variant="text" height={40} />
            </Grid>
         </Grid>
      </Container>
   );
}
